<template>
  <ur-page-container ref="page" class="msp" type="subpage" :show-title="false" title="승강격 실적 조회 결과" :topButton="true">
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <div slot="nav" class="icon-wrapper" @click="fn_popupClose">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '승강격 실적 조회 결과' : '승강격 실적 조회 결과'}}</div>
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area pb30">
              <ur-box-container alignV="start" componentid="" direction="row" class="gray-box bd-T-Ty1 mb0 fexTy3" v-if="pIsManager">
                <div class="fexTy5">
                  <b class="fs18rem crTy-bk1" @click="fn_OpenCnstCard">{{pLv_FofCnslt.label}}</b>
                  <span class="ml4 fs16rem crTy-bk1">컨설턴트</span>
                </div>
                <mo-icon type="picto" @click="fn_OpenMSPAP106P">search</mo-icon>
              </ur-box-container>
            </ur-box-container>
            <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
              <div class="title-block">
                  <div class="fwb fs16rem"><span>유효성적 I (누계)</span></div>
                  <div class="fwb mt4"><span class="fs28rem mr3">{{prfmAmt}}</span><span class="fs20rem">원</span></div>
              </div>
              <div class="gray-bar-block full mt10 fs16rem bd-b-Ty2">
                <div class="gray-bar-li fexTy3 pt15 pb15"><span>부족금액(환산성적기준)</span><span><b class="mr3" :class="fn_getPMClass(dfamtPrfm)">{{$bizUtil.isEmpty(dfamtPrfm) ? '0' : dfamtPrfm}}</b>원</span></div>
                <!-- // 마이너스 : class="price-minus" / 플러스 : class="price-plus" 기본 : class="" -->
              </div>
            </ur-box-container>
          </template>              

          <!-- stickey 영역 -->
          <template #sticky>   
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment con-area mt30 mb10">
              <mo-segment-wrapper solid primary v-model="tab_index">
                <mo-segment-button value="1">Club별 상세</mo-segment-button>
                <mo-segment-button value="2">월별 세부현황</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb0" v-if="tab_index === '1'">
              <div class="con-area fs14rem crTy-bk7">
                <span v-if="searchResult==='1'">심사기준 : {{jdgStr3Ym}}월~{{jdgEndYm}}월</span><!-- 3개월 노출 -->
                <span v-else>심사기준 : {{jdgStr6Ym}}월~{{jdgEndYm}}월</span><!-- 6개월 노출 -->
              </div>
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="status-box-list">
                <!-- 3개월 노출 start -->
                <!-- AFC인 경우 start -->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list" v-if="searchResult==='1'&&lv_isAFC">
                  <div class="list-item" :class="activeClass('550')" ><!-- // status = select/ next class 추가로 표현  -->
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">리더</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm550}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm550)">{{dfamtPrfmValues.dfamtPrfm550}}</b>원</span>
                            <!-- // 마이너스 : class="price-minus" / 플러스 : class="price-plus" 기본 : class="" -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('540')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">스타 I</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm540}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm540)">{{dfamtPrfmValues.dfamtPrfm540}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('530')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">스타 II</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm530}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm530)">{{dfamtPrfmValues.dfamtPrfm530}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('525')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">챌린저</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm525}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm525)">{{dfamtPrfmValues.dfamtPrfm525}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('520')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">액티브</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm520}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm520)">{{dfamtPrfmValues.dfamtPrfm520}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
                <!-- AFC인 경우 end -->
                <!-- 전속인 경우 start -->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list" v-if="searchResult==='1'&&!lv_isAFC">
                  <div class="list-item" :class="activeClass('650')" ><!-- // status = select/ next class 추가로 표현  -->
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">100만Club</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm650}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm650)">{{dfamtPrfmValues.dfamtPrfm650}}</b>원</span>
                            <!-- // 마이너스 : class="price-minus" / 플러스 : class="price-plus" 기본 : class="" -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('640')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">70만Club</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm640}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm640)">{{dfamtPrfmValues.dfamtPrfm640}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('630')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">50만Club</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm630}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm630)">{{dfamtPrfmValues.dfamtPrfm630}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('620')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">30만Club</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm620}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm620)">{{dfamtPrfmValues.dfamtPrfm620}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
                <!-- 전속인 경우 end -->
                <!-- 3개월 노출 end -->
                <!-- 6개월 노출 start -->
                <!-- AFC인 경우 start -->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list" v-if="searchResult==='2'&&lv_isAFC">
                  <div class="list-item" :class="activeClass('690')" ><!-- // status = select/ next class 추가로 표현  -->
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">명인P</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm690}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm690)">{{dfamtPrfmValues.dfamtPrfm690}}</b>원</span>
                            <!-- // 마이너스 : class="price-minus" / 플러스 : class="price-plus" 기본 : class="" -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('680')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">명인</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm680}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm680)">{{dfamtPrfmValues.dfamtPrfm680}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('570')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">슈퍼</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm570}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm570)">{{dfamtPrfmValues.dfamtPrfm570}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('560')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">프로</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm560}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm560)">{{dfamtPrfmValues.dfamtPrfm560}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
                <!-- AFC인 경우 end -->
                <!-- 전속인 경우 start -->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list" v-if="searchResult==='2'&&!lv_isAFC">
                  <div class="list-item" :class="activeClass('690')" ><!-- // status = select/ next class 추가로 표현  -->
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">명인P</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm690}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm690)">{{dfamtPrfmValues.dfamtPrfm690}}</b>원</span>
                            <!-- // 마이너스 : class="price-minus" / 플러스 : class="price-plus" 기본 : class="" -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-item" :class="activeClass('680')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">명인</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm680}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm680)">{{dfamtPrfmValues.dfamtPrfm680}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="list-item" :class="activeClass('670')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">슈퍼</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm670}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm670)">{{dfamtPrfmValues.dfamtPrfm670}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="list-item" :class="activeClass('665')" >
                    <div class="list-item__main">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info row">
                          <span class="bullet">●</span>
                            <span class="name txtSkip">150만 Club</span>
                            <span class="price"><b class="mr3">{{stndPrfmValues.stndPrfm665}}</b>만원</span>
                            <span class="last"><b class="mr3" :class="fn_getPMClass(dfamtPrfmValues.dfamtPrfm665)">{{dfamtPrfmValues.dfamtPrfm665}}</b>원</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </ur-box-container>
                <!-- 6개월 노출 end -->
              </ur-box-container>             
            </ur-box-container>

            <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list pt10 list-expand-type" v-if="tab_index === '2'">
              <mo-list :list-data="aPMmtypActulSVO">
                <template #list-item="{item, index}">
                  <mo-list-item expandable v-if="searchResult==='1' ? index < 3 : true">
                    <div class="list-item__contents">
                      <div class="list-item__contents__title mb0">
                        <span class="name txtSkip fs18rem">{{'심사월-' + (index + 1) + '월'}}</span>
                      </div>
                    </div>
                    <template v-slot:expand>
                      <div class="list-item__contents">
                        <div class="gray-bar-block full bdTN">
                          <div class="gray-bar-li fexTy3"><span>환산(원)</span><span><b class="mr4">{{$commonUtil.numberWithCommas(item.cnvlActul)}}</b></span></div>
                          <div class="gray-bar-li fexTy3"><span>환수환산(원)</span><span><b class="mr4">{{$commonUtil.numberWithCommas(item.rdmtnCnvlActul)}}</b></span></div>
                          <div class="gray-bar-li fexTy3"><span>적용유지율(%)</span><span><b class="mr4">{{item.ukepRat213}}</b></span></div>
                          <div class="gray-bar-li fexTy3"><span>유효성적 I (원)</span><span><b class="mr4">{{$commonUtil.numberWithCommas(item.avlPrfmAmt)}}</b></span></div>
                        </div>
                      </div>
                    </template>
                  </mo-list-item>
                </template>
              </mo-list>
            </ur-box-container>

            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-add-area pt30 pb0">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li v-if="isClbrtP">명인P : 13차월 이상&직전 분기 명인 중 기준액 달성자</li>
                  <li>신인 리크루팅 특승, 직책자 등 특승 반영前예시입니다.</li>
                </ul> 
              </ur-box-container>               
            </ur-box-container>

            <!-- no-data --> 
            <!-- <ur-box-container alignV="start" componentid="" direction="column" class="no-data">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info middle-space">
                    <span class="ns-ftcr-gray"><p class="mt10">상세조회 조건을 설정해 주세요</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>               
            -->
          </template>          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MSPAP511P from '@/ui/ap/MSPAP511P'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP111P",
    screenId: "MSPAP111P",
    components: {},
    props: {
      pIsManager: Boolean,
      pLv_FofCnslt: Object,
      pCnsltNo: String,
      pInqrScSlct: String,
      pPrextScSlct: String,
      pNwmanScSlct: String,
      pGoalItem: String
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      if (this.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' ) {
        this.lv_isAFC = true
        this.stndPrfmValues = JSON.parse(JSON.stringify(this.stndPrfmValues_AFC))
        this.dfamtPrfmValues = JSON.parse(JSON.stringify(this.dfamtPrfmValues_AFC))
      }

    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      console.log('props ===>>> ', this.$props)

      if (this.pGoalItem === '690') this.isClbrtP = true

      this.fn_SelPodsActul()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        tab_index: '1', // tab contant 선택;
        isClbrtP: false, // 명인P여부
        jdgStr3Ym: '',
        jdgStr6Ym: '',
        jdgEndYm: '',
        searchResult: '', // '1':3개월노출, '2':6개월노출, (월별세부내역)
        prfmAmt: '', // 유효성적
        mnthprfmAmt3: 0,
        mnthprfmAmt6: 0,
        dfamtPrfm: '', // 차액성적
        aPStndPrfmSVO: [],
        aPMmtypActulSVO: [],
        stndPrfmValues_AFC: {
          stndPrfm520: '0',
          stndPrfm525: '0',
          stndPrfm530: '0',
          stndPrfm540: '0',
          stndPrfm550: '0',
          stndPrfm560: '0',
          stndPrfm570: '0',
          stndPrfm680: '0',
          stndPrfm690: '0'
        },
        stndPrfmValues: {
          stndPrfm620: '0',
          stndPrfm630: '0',
          stndPrfm640: '0',
          stndPrfm650: '0',
          stndPrfm665: '0',
          stndPrfm680: '0',
          stndPrfm690: '0'
        },
        dfamtPrfmValues_AFC: {
          dfamtPrfm520: '0',
          dfamtPrfm525: '0',
          dfamtPrfm530: '0',
          dfamtPrfm540: '0',
          dfamtPrfm550: '0',
          dfamtPrfm560: '0',
          dfamtPrfm570: '0',
          dfamtPrfm680: '0',
          dfamtPrfm690: '0'
        },
        dfamtPrfmValues: {
          dfamtPrfm620: '0',
          dfamtPrfm630: '0',
          dfamtPrfm640: '0',
          dfamtPrfm650: '0',
          dfamtPrfm665: '0',
          dfamtPrfm680: '0',
          dfamtPrfm690: '0'
        },
        mmtyp3Actul: [
          {
            tit: '환산(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0'
          },
          {
            tit: '환수환산(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0'
          },
          {
            tit: '적용유지율(%)',
            mon1: '0',
            mon2: '0',
            mon3: '0'
          },
          {
            tit: '유효성적Ⅰ(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0'
          }
          // ,
          // {
          //   tit: '리크루팅(명)',
          //   mon1: '0',
          //   mon2: '0',
          //   mon3: '0'
          // }
        ],
        mmtyp6Actul: [
          {
            tit: '환산(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0',
            mon4: '0',
            mon5: '0',
            mon6: '-'
          },
          {
            tit: '환수환산(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0',
            mon4: '0',
            mon5: '0',
            mon6: '0'
          },
          {
            tit: '적용유지율(%)',
            mon1: '0',
            mon2: '0',
            mon3: '0',
            mon4: '0',
            mon5: '0',
            mon6: '0'
          },
          {
            tit: '유효성적Ⅰ(원)',
            mon1: '0',
            mon2: '0',
            mon3: '0',
            mon4: '0',
            mon5: '0',
            mon6: '0'
          }
          // ,
          // {
          //   tit: '리크루팅(명)',
          //   mon1: '0',
          //   mon2: '0',
          //   mon3: '0',
          //   mon4: '0',
          //   mon5: '0',
          //   mon6: '0'
          // }
        ],
        lv_isAFC: false
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: {
      searchResult () {
        console.log('searchResult : ' + this.searchResult )
        console.log('mnthprfmAmt3 : ' + this.mnthprfmAmt3 )
        console.log('mnthprfmAmt6 : ' + this.mnthprfmAmt6 )
        if ( this.searchResult === '1') {
          this.prfmAmt = this.$bizUtil.numberWithCommas(this.mnthprfmAmt3)
        } else if ( this.searchResult === '2') {
          this.prfmAmt = this.$bizUtil.numberWithCommas(this.mnthprfmAmt6)
        } else {
          this.prfmAmt = 0
          this.dfamtPrfm = '0'
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_OpenMSPAP106P() {
        this.$emit('onPopupClose', true)
      },
      fn_getPMClass(val) {
        let rtn = 'price-plus'
        if(!this.$bizUtil.isEmpty(val)){
          if(val.indexOf('-') === 0) rtn = 'price-minus'
        }
        return rtn
      },
      activeClass(v) {
        console.log('this.pGoalItem >> ', this.pGoalItem, v)
        if (this.pGoalItem === v) return 'select'
      },
      fn_popupClose() {
        this.$emit('onPopupClose')
      },
      /******************************************************************************
       * Function명 : fn_SelPodsActul
       * 설명       : 승강격 실적을 조회한다.
      ******************************************************************************/
      fn_SelPodsActul () {
        console.log('===============fn_SelPodsActul===============')
        var lv_Vm = this
        var trnstId = 'txTSSAP00S6'
        var pParam = {
          cnsltNo: lv_Vm.pCnsltNo,
          inqrSc: lv_Vm.pInqrScSlct,
          actulYm: lv_Vm.fn_SetActulYm()
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          console.log(response)

          if ( response.body != null) {
            lv_Vm.mnthprfmAmt3 = response.body.mnthprfmAmt3
            lv_Vm.mnthprfmAmt6 = response.body.mnthprfmAmt6

            // 기준성적
            if ( response.body.aPStndPrfmSVO != null) {
              lv_Vm.aPStndPrfmSVO = response.body.aPStndPrfmSVO

              for (let j = 0; j < lv_Vm.aPStndPrfmSVO.length; j++) {
                lv_Vm.stndPrfmValues['stndPrfm' + lv_Vm.aPStndPrfmSVO[j].chnlQulfCd.substring(2, 5)] = lv_Vm.$commonUtil.numberWithCommas(( Number(lv_Vm.aPStndPrfmSVO[j].stndPrfm) / 10000 ))
                lv_Vm.dfamtPrfmValues['dfamtPrfm' + lv_Vm.aPStndPrfmSVO[j].chnlQulfCd.substring(2, 5)] = (Number(lv_Vm.aPStndPrfmSVO[j].dfamtPrfm) < 0 ? '-' : '+') + lv_Vm.$commonUtil.numberWithCommas(Math.abs(Number(lv_Vm.aPStndPrfmSVO[j].dfamtPrfm)))

                if ( lv_Vm.pGoalItem === lv_Vm.aPStndPrfmSVO[j].chnlQulfCd.substring(2, 5) ) {
                  lv_Vm.dfamtPrfm = lv_Vm.dfamtPrfmValues['dfamtPrfm' + lv_Vm.pGoalItem]
                }
              }
            }

            // // AFC도 명인P 선택/조회 가능하도록 주석처리 - [ASR221200639] 수수료 제도 개정에 따른 사랑On 자격심사 화면 개선 (채널제도P 이지민 프로)
            // if ( lv_Vm.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' ) {
            //   lv_Vm.stndPrfmValues.stndPrfm690 = '-'
            //   lv_Vm.dfamtPrfmValues.dfamtPrfm690 = '-'
            // }

            // 월별실적
            if ( response.body.aPMmtypActulSVO != null) {
              lv_Vm.aPMmtypActulSVO = response.body.aPMmtypActulSVO

              var strCnvlActul = '' // 환산
              var strRdmtnCnvlActul = '' // 환수환산
              var strUkepRat213 = '' // 적용유지율(%)
              var strAvlPrfmAmt = '' // 유효성적(원)
              // var strRecrNprsnFgr = '' // 리크루팅(원)

              for (let j = 0; j < lv_Vm.aPMmtypActulSVO.length; j++) {
                if ( j === 0 ) lv_Vm.jdgEndYm = lv_Vm.$bizUtil.dateYyMmFormat(lv_Vm.aPMmtypActulSVO[j].actulYm)

                lv_Vm.mmtyp6Actul[0]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].cnvlActul)
                lv_Vm.mmtyp6Actul[1]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].rdmtnCnvlActul)
                lv_Vm.mmtyp6Actul[2]['mon' + ( j + 1 )] = lv_Vm.aPMmtypActulSVO[j].ukepRat213
                lv_Vm.mmtyp6Actul[3]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].avlPrfmAmt)

                lv_Vm.jdgStr6Ym = lv_Vm.$bizUtil.dateYyMmFormat(lv_Vm.aPMmtypActulSVO[j].actulYm)

                if ( j < 3) {
                  lv_Vm.mmtyp3Actul[0]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].cnvlActul)
                  lv_Vm.mmtyp3Actul[1]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].rdmtnCnvlActul)
                  lv_Vm.mmtyp3Actul[2]['mon' + ( j + 1 )] = lv_Vm.aPMmtypActulSVO[j].ukepRat213
                  lv_Vm.mmtyp3Actul[3]['mon' + ( j + 1 )] = lv_Vm.$commonUtil.numberWithCommas(lv_Vm.aPMmtypActulSVO[j].avlPrfmAmt)

                  lv_Vm.jdgStr3Ym = lv_Vm.$bizUtil.dateYyMmFormat(lv_Vm.aPMmtypActulSVO[j].actulYm)
                }
              }
            }

            // searchResult : 1 - 3개월, 2 - 6개월
            if (lv_Vm.lv_isAFC) {
              if ('560,570,680,690'.indexOf(lv_Vm.pGoalItem) > -1) {
                lv_Vm.searchResult = '2'
              } else {
                if ( lv_Vm.pInqrScSlct === '1' && lv_Vm.pNwmanScSlct === '07' ) {
                  lv_Vm.searchResult = '2'
                } else {
                  lv_Vm.searchResult = '1'
                }
              }
            } else {
              if ('665,680,690'.indexOf(lv_Vm.pGoalItem) > -1) {
                lv_Vm.searchResult = '2'
              } else {
                if ( lv_Vm.pInqrScSlct === '1' && lv_Vm.pNwmanScSlct === '07' ) {
                  lv_Vm.searchResult = '2'
                } else {
                  lv_Vm.searchResult = '1'
                }
              }
            }

          } else {
            // 호출 오류
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명 : fn_SetActulYm
       * 설명       : 실적년월을 계산한다.
      ******************************************************************************/
      fn_SetActulYm () {
        var rtnValue = ''
        var year = ''
        var nowMon = Number(moment(new Date().toISOString()).format('MM'))
        console.log('fn_SetActulYm nowMon : ' + nowMon )
        var month = ''
        // 1 : 신인 : 99999 + 차월값 ( 4, 7 )
        // 2 : 기존 : (As-Is) 심사월( YYYYMM ), 현재월이 7월 이후에는 내년으로 설정.
        // -> (To-Be) 현재월이 1~3월일 경우 올해 4월/7월, 4~6월일 경우 올해 7월/10월, 7~9월일 경우 올해 10월/내년 1월, 10~12월일 경우 내년 1월/4월로 설정.
        // (202009 채널제도P 임경미 프로 변경)

        if ( this.pInqrScSlct === '1' ) {
          rtnValue = '9999' + this.pNwmanScSlct
        } else if ( this.pInqrScSlct === '2' ) {
          month = this.pPrextScSlct
          if (nowMon < 7) {
            year = moment().format('YYYY')
          } else if (nowMon >= 7 && nowMon < 10) {
            if (month === '10') {
              year = moment().format('YYYY')
            } else {
              year = moment(moment().add(1, 'year')).format('YYYY')
            }
          } else {
            year = moment(moment().add(1, 'year')).format('YYYY')
          }
          rtnValue = year + month
        } else {
          this.getStore('confirm').dispatch('ADD', '심사월을 선택하세요.')
        }

        console.log('moment test : ' + moment().format('YYYY'))
        console.log('moment test : ' + moment().format('MM'))

        console.log('fn_SetActulYm retrurn : ' + rtnValue )
        return rtnValue
      },
      fn_OpenCnstCard() {
        this.popupAP511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: this.pLv_FofCnslt.label,
            pCnsltNo: this.pLv_FofCnslt.key,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popupAP511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popupAP511)
            }
          }
        })
      }

    }
  };
</script>
<style scoped>
</style>