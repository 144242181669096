-/*
 * 업무구분: 승강격실적
 * 화 면 명: MSPAP110M
 * 화면설명: 승강격실적
 * 접근권한: 
 * 작 성 일: 최준석
 * 작 성 자: 2022.12.27
 */
<template>
  <ur-page-container ref="page" :show-title="false" class="msp" title="승강격실적" type="subpage" :topButton="true" :action-type="isManager ? 'search' : 'subpage'" >
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="26" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_goMain" />
              <div slot="nav" class="icon-wrapper" @click="fn_goMain">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '승강격 실적' : '승강격 실적'}}</div>
              <div slot="action" v-if="isManager" @click="fn_OpenMSPAP106P"><mo-icon>search</mo-icon></div><!-- // 지점장이상 진입 시에만 노출 -->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-info-area pb30">
              <ur-box-container alignV="start" componentid="" direction="row" class="gray-box bd-T-Ty1 mb0" v-if="isManager">
                <div class="fexTy5">
                  <b class="fs18rem crTy-bk1" @click="fn_OpenCnstCard">{{lv_FofCnslt.label}}</b>
                  <span class="ml4 fs16rem crTy-bk1">컨설턴트</span>
                </div>
              </ur-box-container>
            </ur-box-container>
          </template> 

          <!-- scroll 영역 -->
          <template #scroll> 
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pt0">  
              <!-- 기준 -->
              <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
                <span class="title fs18rem fwm mb10">기준</span>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <mo-segment-wrapper solid primary v-model="inqrScCd.inqrScSlct" class="chip-type-wrap chip-ty1" @click="fn_InqrScSlct">
                    <mo-segment-button :value="inqrScCd.inqrScValues[0].key">신인</mo-segment-button>
                    <mo-segment-button :value="inqrScCd.inqrScValues[1].key">기존</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </ur-box-container>
              <!-- //기준 -->
              <!-- 심사월 -->
              <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
                <span class="title fs18rem fwm mb10">심사월</span>
                <!--기존 -->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment" v-if="inqrScCd.inqrScSlct === '2'">
                  <mo-segment-wrapper solid primary v-model="inqrScCd.prextScSlct" class="chip-type-wrap chip-ty1">
                    <mo-segment-button :value="inqrScCd.prextScValues[0].key">{{inqrScCd.prextScValues[0].label}}</mo-segment-button>
                    <mo-segment-button :value="inqrScCd.prextScValues[1].key">{{inqrScCd.prextScValues[1].label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
                <!--신인-->
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment" v-else>
                  <mo-segment-wrapper solid primary v-model="inqrScCd.nwmanScSlct" class="chip-type-wrap chip-ty1" @click="fn_NwmanScSlct">
                    <mo-segment-button :value="inqrScCd.nwmanScValues[0].key">{{inqrScCd.nwmanScValues[0].label}}</mo-segment-button>
                    <mo-segment-button :value="inqrScCd.nwmanScValues[1].key">{{inqrScCd.nwmanScValues[1].label}}</mo-segment-button>
                  </mo-segment-wrapper>
                </ur-box-container>
              </ur-box-container>
              <!-- //심사월 -->
              <!-- 목표Club -->
              <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
                <span class="title mb10 fwm fs18rem">목표Club</span>
                <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
                  <msp-bottom-select :items="goalItems" v-model="goalItem" itemHeight="374" :itemValue="'key'" :itemText="'label'" underline closeBtn scrolling class="ns-dropdown-sheet" bottom-title="목표Club 선택" placeholder="선택하세요"/>
                </ur-box-container>
              </ur-box-container>
              <!-- // 목표Club -->

              <ur-box-container alignV="start" componentid="" direction="column"  class="ns-btn-relative bottom60">
                <div class="relative-div">
                  <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SelPodsActul">조회</mo-button>
                </div>
              </ur-box-container>
            </ur-box-container> 

            <!-- no-data --> 
            <ur-box-container alignV="start" componentid="" direction="column" class="no-data" v-if="(isManager && !isSelectUser)">
              <mo-list-item ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info small-space">
                    <span class="ns-ftcr-gray"><p class="mt10">상세조회 조건을 설정해 주세요</p></span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>                
           
            
          </template>          
        </mo-collapsing-header-view>
      </div>
    </ur-box-container>    
    
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  import Msg from '@/systems/webkit/msg/msg'
  import MSPAP106P from '@/ui/ap/MSPAP106P'
  import MSPAP111P from '@/ui/ap/MSPAP111P'
  import MSPAP511P from '@/ui/ap/MSPAP511P'
  
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP110M",
    screenId: "MSPAP110M",
    components: {
      MspBottomSelect,
      MSPAP106P,
      MSPAP111P
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_HeaderviewScrollCompID: '',
        popupAP106: null,
        popupAP111: null,
        cnsltNo: '',
        isManager: false, // 최초 영업관리자를 위한 조건값
        isSelectUser: false, // 영업관리자가 설계사를 선택하였는지 여부
        lv_FofCnslt: {key: '0', label: ''}, // 선택컨설턴트
        lv_IsFirst: true,
        lpmfc: 0,
        
        tableClass: '',
        goalItem: '',
        goalItems: [
          {key: '690', label: '명인P'},
          {key: '680', label: '명인'},
          {key: '665', label: '150만 Club'},
          {key: '650', label: '100만 Club'},
          {key: '640', label: '70만 Club'},
          {key: '630', label: '50만 Club'},
          {key: '620', label: '30만 Club'}
        ],
        goalItems_AFC: [
          {key: '690', label: '명인P'},
          {key: '680', label: '명인'},
          {key: '570', label: '슈퍼'},
          {key: '560', label: '프로'},
          {key: '550', label: '리더'},
          {key: '540', label: '스타 I'},
          {key: '530', label: '스타 II'},
          {key: '525', label: '챌린저'},
          {key: '520', label: '액티브'}
        ],
        inqrScCd: {
          inqrScSlct: '1',
          inqrScValues: [
            {key: '1', label: '신인'},
            {key: '2', label: '기존'}
          ],
          prextScSlct: '',
          prextScValues: [],
          prextScValuesInit: [
            {key: '01', label: '1월'},
            {key: '04', label: '4월'},
            {key: '07', label: '7월'},
            {key: '10', label: '10월'}
          ],
          nwmanScSlct: '04',
          nwmanScValues: [
            {key: '04', label: '4차월'},
            {key: '07', label: '7차월'}
          ]
        },
        lv_isAFC: false
        
      }
    },
    /************************************************************************************************
    * Lyfe Cycle 함수 정의 영역
    ************************************************************************************************/
    created () {
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_goMain)

      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }

      if (this.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' ) {
        this.lv_isAFC = true
        this.goalItems = JSON.parse(JSON.stringify(this.goalItems_AFC))
        this.stndPrfmValues = JSON.parse(JSON.stringify(this.stndPrfmValues_AFC))
        this.dfamtPrfmValues = JSON.parse(JSON.stringify(this.dfamtPrfmValues_AFC))
      }
    },
    mounted () {
      this.$bizUtil.insSrnLog(this.$options.screenId)

      console.log( '로그인 사용자정보 -------------')
      console.log( this.getStore('userInfo').getters.getUserInfo.isBMngr )
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
      console.log( this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
      console.log( '로그인 사용자정보 -------------')

      // FC(FC), SFC(FC), WM(SA), GFC(GP), AFC(AM), TC 가 아닌 경우에는 메시지 처리 후 종료
      // if ( 'FC,GP,SA,AM'.indexOf(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd) === -1 ) {
      if ( 'BK,DI,DS,FS,GA,JK'.indexOf(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd) > -1 ) {
        this.getStore('confirm').dispatch('ADD', 'GA/신채널,금융서비스 등에서는 해당 서비스를 이용하실 수 없습니다.')
        this.$router.go(-1)
      }

      if ( !this.isManager ) {
        this.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
        // this.cnsltNo = '0001489835' // 신인 테스트 사번
      } else {
        if(this.lv_IsFirst){
          this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              single: true,
              title: "",
              content: '컨설턴트를 먼저 선택해 주세요.',
              title_pos_btn: "확인"
            },
            listeners: {
              onPopupConfirm: () => {
                console.log('onPopupConfirm call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              },
              onPopupCancel: () => {
                console.log('onPopupCancel call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              },
              onPopupClose: () => {
                console.log('onPopupClose call')
                this.$bottomModal.close(this.lv_AlertPop)
                this.fn_OpenMSPAP106P()
              }
            }
          })
        }
      }

      // 현재월이 포함될 심사월을 표시한다.
      var nowMonth = Number(moment(new Date().toISOString()).format('MM'))

      this.inqrScCd.prextScValues.pop()
      this.inqrScCd.prextScSlct = ''

      var initVal = false
      var chkVal = 0
      var prextScValuesLen = this.inqrScCd.prextScValuesInit.length
      if (nowMonth < 7 || nowMonth > 9) {
        for (let i = 0; i < prextScValuesLen; i++) {
          chkVal = Number(this.inqrScCd.prextScValuesInit[i].key) > nowMonth ? Number(this.inqrScCd.prextScValuesInit[i].key) : Number(this.inqrScCd.prextScValuesInit[i].key) + 12
          if ( Math.abs(chkVal - nowMonth) < 7 ) {
            this.inqrScCd.prextScValues.push(this.inqrScCd.prextScValuesInit[i])
            if (!initVal) {
              this.inqrScCd.prextScSlct = this.inqrScCd.prextScValuesInit[i].key
              initVal = true
            }
          }
        }
      } else { // 7~9월의 경우 10월/1월 표시 (202009 채널제도P 임경미)
        for (let i = prextScValuesLen - 1; i > -1; i--) {
          chkVal = Number(this.inqrScCd.prextScValuesInit[i].key) > nowMonth ? Number(this.inqrScCd.prextScValuesInit[i].key) : Number(this.inqrScCd.prextScValuesInit[i].key) + 12
          if ( Math.abs(chkVal - nowMonth) < 7 ) {
            this.inqrScCd.prextScValues.push(this.inqrScCd.prextScValuesInit[i])
            if (!initVal) {
              this.inqrScCd.prextScSlct = this.inqrScCd.prextScValuesInit[i].key
              initVal = true
            }
          }
        }
      }

    },
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_goMain)
    },
    computed: {
    },
    watch: {
      goalItem () {
        this.fn_ChoicePodsActul()
      },
      cnsltNo () {
        if ( this.cnsltNo !== null && this.cnsltNo !== '' ) {
          this.fn_GetFcInfo()
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_GetFcInfo
       * 설명       : FC의 차월정보를 조회한다.
      ******************************************************************************/
      fn_GetFcInfo () {
        var lv_Vm = this
        var trnstId = 'txTSSAP00S3'
        var pParam = {
          cnsltNo: lv_Vm.cnsltNo
        }

        lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
          if (lv_Vm.DEBUG) console.log(response)
          if ( response.body != null) {
            lv_Vm.lpmfc = response.body.lpmfc // 위촉차월
            // 신인 3차월 : 4차월 이하이면 신인 3차월로 설정
            // 신인 7차월 : 4차월 초과 7차월 이하이면 7차월로 설정
            // 기존 : 7차월 초과이면 기존으로 설정
            if ( Number(lv_Vm.lpmfc) > 7 ) {
              lv_Vm.inqrScCd.inqrScSlct = ''
              lv_Vm.inqrScCd.inqrScSlct = lv_Vm.inqrScCd.inqrScValues[1].key
            } else if ( Number(lv_Vm.lpmfc) <= 7 && Number(lv_Vm.lpmfc) > 4 ) {
              lv_Vm.inqrScCd.nwmanScSlct = ''
              lv_Vm.inqrScCd.inqrScSlct = lv_Vm.inqrScCd.inqrScValues[0].key
              lv_Vm.inqrScCd.nwmanScSlct = lv_Vm.inqrScCd.nwmanScValues[1].key
            } else {
              lv_Vm.inqrScCd.nwmanScSlct = ''
              lv_Vm.inqrScCd.inqrScSlct = lv_Vm.inqrScCd.inqrScValues[0].key
              lv_Vm.inqrScCd.nwmanScSlct = lv_Vm.inqrScCd.nwmanScValues[0].key
            }
            console.log('위촉차월 : ' + lv_Vm.lpmfc )
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        }).catch(function (error) {
          window.vue.error(error)
        })
      },
      /******************************************************************************
       * Function명 : fn_ChoicePodsActul
       * 설명       : 목표 Club을 선택한다.
      ******************************************************************************/
      fn_ChoicePodsActul () {
        if(!this.$bizUtil.isEmpty(this.goalItem)){
          if (this.lv_isAFC) {
            if ( this.inqrScCd.inqrScSlct === '1' && this.inqrScCd.nwmanScSlct === '04' && '560,570,680,690'.indexOf(this.goalItem) > -1 ) {
              this.goalItem = this.goalItems[4].key
              this.getStore('confirm').dispatch('ADD', '신인 4차월은 프로 이상\n 자격 선택이 불가합니다.')
              return
            }
          } else {
            if ( this.inqrScCd.inqrScSlct === '1' && this.inqrScCd.nwmanScSlct === '04' && '665,680,690'.indexOf(this.goalItem) > -1 ) {
              this.goalItem = this.goalItems[3].key
              this.getStore('confirm').dispatch('ADD', '신인 4차월은 150만 Club 이상\n 자격 선택이 불가합니다.')
              return
            }
          }
        }

        // // AFC도 명인P 선택/조회 가능하도록 주석처리 => [ASR221200639] 수수료 제도 개정에 따른 사랑On 자격심사 화면 개선 (채널제도P 이지민 프로)
        // console.log(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd)
        // if ( this.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' && this.goalItem === '690' ) {
        //   this.goalItem = this.goalItems[4].key
        //   this.getStore('confirm').dispatch('ADD', 'AFC소속 설계사는 명인(P)를\n 선택하실 수 없습니다.')
        //   return
        // }
      },
      fn_OpenMSPAP106P(){
        const lp_Vm = this  
        this.popupAP106 = this.$bottomModal.open(MSPAP106P, {
          properties: {
            pPage: 'MSPAP110M',
          },
          listeners: {
            onPopupSearch: (pData) => {
              console.log('onPopupSearch >> ' , pData)
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)

              if(pData != null && pData != undefined){
                lp_Vm.lv_FofCnslt = pData
                lp_Vm.cnsltNo = lp_Vm.lv_FofCnslt.key
              }

              lp_Vm.fn_choiceuser()
            },
            onPopupClose: () => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP106)
            }
          }
        })
      },
      fn_choiceuser() {
        if ( this.lv_FofCnslt.key === '' || this.lv_FofCnslt.key === '0') {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택해주세요.')
          return
        }

        this.isSelectUser = true
        this.goalItem = ''
      },
      /******************************************************************************
       * Function명 : fn_SelPodsActul
       * 설명       : 승강격 실적을 조회한다.
      ******************************************************************************/
      fn_SelPodsActul () {
        console.log('===============fn_SelPodsActul===============')
        var lv_Vm = this

        if ( lv_Vm.cnsltNo === '' ) {
          this.getStore('confirm').dispatch('ADD', '컨설턴트를 선택하세요.')
          return
        }

        if ( lv_Vm.goalItem.length === 0 || lv_Vm.goalItem === '' ) {
          this.getStore('confirm').dispatch('ADD', '목표 급호를 선택하세요.')
          return
        }

        // AFC도 명인P 선택/조회 가능하도록 주석처리 => [ASR221200639] 수수료 제도 개정에 따른 사랑On 자격심사 화면 개선 (채널제도P 이지민 프로)
        // if ( lv_Vm.getStore('userInfo').getters.getUserInfo.srvcMngScCd === 'AM' && lv_Vm.goalItem === '690' ) {
        //   this.getStore('confirm').dispatch('ADD', 'AFC소속 설계사는 명인(P)를\n 선택하실 수 없습니다.')
        //   return
        // }

        // 신인 4차월 선택 시 프로이상 급호는 선택 불가
        if (lv_Vm.lv_isAFC) {
          if ( lv_Vm.inqrScCd.inqrScSlct === '1' && lv_Vm.inqrScCd.nwmanScSlct === '04' && '560,570,680,690'.indexOf(lv_Vm.goalItem) > -1 ) {
            this.getStore('confirm').dispatch('ADD', '신인 4차월은 프로 이상\n 자격 선택이 불가합니다.')
            lv_Vm.goalItem = lv_Vm.goalItems[4].key
            return
          }
        } else {
          if ( lv_Vm.inqrScCd.inqrScSlct === '1' && lv_Vm.inqrScCd.nwmanScSlct === '04' && '665,680,690'.indexOf(lv_Vm.goalItem) > -1 ) {
            this.getStore('confirm').dispatch('ADD', '신인 4차월은 150만 Club 이상\n 자격 선택이 불가합니다.')
            lv_Vm.goalItem = lv_Vm.goalItems[3].key
            return
          }
        }
        
        lv_Vm.fn_OpenMSPAP111P()
      },
      fn_OpenMSPAP111P(){
        const lp_Vm = this
        
        this.popupAP111 = this.$bottomModal.open(MSPAP111P, {
          properties: {
            pPage: 'MSPAP110M',
            pIsManager: lp_Vm.isManager,
            pLv_FofCnslt: lp_Vm.lv_FofCnslt,
            pCnsltNo: lp_Vm.cnsltNo,
            pInqrScSlct: lp_Vm.inqrScCd.inqrScSlct,
            pPrextScSlct: lp_Vm.inqrScCd.prextScSlct,
            pNwmanScSlct: lp_Vm.inqrScCd.nwmanScSlct,
            pGoalItem: lp_Vm.goalItem

          },
          listeners: {
            onPopupClose: (pData) => {
              console.log('onPopupClose')
              // 모달 닫기
              lp_Vm.$bottomModal.close(lp_Vm.popupAP111)
              
              if(pData){
                setTimeout(() => {
                  this.fn_OpenMSPAP106P()
                }, 500)
              }
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      /*******************************************************************************
      * Function명: fn_goMain
      * 설명: 메인으로 이동
      *******************************************************************************/
      fn_goMain() {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
        }else{
          this.$router.go(-1)
        }
      },
      fn_InqrScSlct (e) {
        this.$nextTick(() => {
          if ( this.inqrScCd.inqrScSlct === '1' && this.lpmfc > 7 ) {
            this.getStore('confirm').dispatch('ADD', '8차월 이상인 FC는\n 신인으로 조회할 수 없습니다.')
            this.inqrScCd.inqrScSlct = this.inqrScCd.inqrScValues[1].key
            return
          }
        })
      },
      fn_NwmanScSlct () {
        this.$nextTick(() => {
          if ( this.inqrScCd.inqrScSlct === '1' && this.lpmfc > 4 && this.inqrScCd.nwmanScSlct === '04' ) {
            this.getStore('confirm').dispatch('ADD', '5차월 이상인 FC는\n 신인 4차월로 조회할 수 없습니다.')
            this.inqrScCd.nwmanScSlct = this.inqrScCd.nwmanScValues[1].key
          }
        })
      },
      fn_OpenCnstCard() {
        this.popupAP511 = this.$bottomModal.open(MSPAP511P, {
          properties: {
            pCnsltNm: this.lv_FofCnslt.label,
            pCnsltNo: this.lv_FofCnslt.key,
            FCTelBtn: "전화하기",
            FCSMSBtn: "문자하기"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(this.popupAP511)
            },
            onPopupClose: () => {
              this.$bottomModal.close(this.popupAP511)
            }
          }
        })
      }
    }
  }
</script>
<style scoped>
</style>